<template>
  <div class="accueil" ref="accueil" tabindex="-1" @keyup.space="avancer">
    <transition name="fade">
      <div v-if="afficherLogo">
        <img src="@/assets/logo.png" class="logo" />
        <p class="cta">Appuyez sur le bouton pour jouer !</p>
      </div>
    </transition>
    <transition name="fade">
      <div v-if="afficherLogoLieu">
        <img src="@/assets/logo-lieu.png" class="logo-lieu mt-5" />
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "Accueil",
  data() {
    return {
      afficherLogo: false,
      afficherLogoLieu: false,
    };
  },
  mounted() {
    this.$store.dispatch("chargerLots");

    this.$refs.accueil.focus();
    setTimeout(() => {
      this.afficherLogoLieu = true;
      setTimeout(() => {
        this.afficherLogo = true;
        // window.addEventListener("keyup", this.avancer);
      }, 2000);
    }, 500);
  },
  methods: {
    avancer() {
      ion.sound.play("gong");
      setTimeout(() => {
        this.$router.push("decompte");
      }, 1000);
    },
  },
};
</script>
<style lang="scss">
.accueil {
  position: relative;
  height: 100%;
  text-align: center;
  .logo {
    position: absolute;
    max-height: 70%;
    max-width: 80%;
    top: 5%;
    left: 50%;
    transform: translateX(-50%);
  }
  .cta {
    position: absolute;
    bottom: 25%;
    left: 50%;
    transform: translateX(-50%) rotate(-5deg);
    color: white;
    font-size: 3vh;
    background: #f58420;
    font-weight: bold;
    padding: 0.5vh 1vh;
  }
  .logo-lieu {
    position: absolute;
    max-height: 20%;
    max-width: 15%;
    bottom: 5%;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>